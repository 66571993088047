import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import abstractShapes11Svg from '../../assets/svg/components/abstract-shapes-11.svg';
import abstractShapes19Svg from '../../assets/svg/components/abstract-shapes-19.svg';
import abstractShapes22Svg from '../../assets/svg/components/abstract-shapes-22.svg';
import dots4Svg from '../../assets/svg/components/dots-4.svg';
import angularIcon from '../../assets/svg/technologies/angular.svg';
import awsIcon from '../../assets/svg/technologies/aws.svg';
import bootstrapIcon from '../../assets/svg/technologies/bootstrap-4.svg';
import ngrxIcon from '../../assets/svg/technologies/ngrx.svg';
import nodeJsIcon from '../../assets/svg/technologies/nodejs.svg';
import rxjsIcon from '../../assets/svg/technologies/rxjs.svg';
import typescriptIcon from '../../assets/svg/technologies/typescript.svg';
import LocalizedLink from '../../components/LocalizedLink';
import SEO from '../../components/Seo';
import SVG from '../../components/Svg';

const RoomexCaseStudyPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.caseStudies.roomex.title')}
        lang={i18n.language}
        description={t('meta.caseStudies.roomex.description')}
      />

      <div className="gradient-y-gray position-relative">
        <div className="space-top-3 space-top-lg-2 space-bottom-2 space-bottom-lg-3">
          <div className="container mt-lg-5">
            <div className="row">
              <div className="col-lg-5 align-self-center mb-5 mb-md-0 ">
                <div className="mb-4">
                  <h1 className="display-4">Roomex</h1>
                  <p className="font-weight-bold">{t('caseStudies.3.subtitle')}</p>
                  <p>{t('caseStudies.3.text')}</p>
                </div>

                <div>
                  <LocalizedLink
                    to="/contact"
                    className="btn btn-primary transition-3d-hover text-capitalize mb-2 mb-sm-0 mr-3"
                  >
                    {t('label.findMore')}
                  </LocalizedLink>
                  <a
                    href="https://www.roomex.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary transition-3d-hover text-capitalize"
                  >
                    {t('label.visitWebsite')}
                  </a>
                </div>
              </div>
              <div className="col-lg-7 align-self-center">
                <figure className="w-100 text-center">
                  <StaticImage
                    className="img-fluid rounded-pseudo shadow-lg"
                    src="../../assets/images/case-studies/roomex.png"
                    alt="Roomex B2B travel management platform"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <figure>
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
          >
            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
          </svg>
        </figure>
      </div>

      <section className="container space-2 space-bottom-lg-3 space-top-lg-4">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <h2>{t('label.challenge')}</h2>
            <p>{t('caseStudies.3.challenge')}</p>
          </div>
          <figure className="col-lg-6 text-center" data-aos="fade-left">
            <StaticImage
              className="img-fluid rounded-pseudo shadow-lg"
              src="../../assets/images/case-studies/roomex_1.png"
              alt="Roomex Case Study"
            />
          </figure>
        </div>
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0" data-aos="fade-left">
              <h2>{t('label.solution')}</h2>
              <p>{t('caseStudies.3.solution')}</p>
            </div>
            <figure className="col-lg-6 order-lg-1 text-center" data-aos="fade-right">
              <StaticImage
                className="img-fluid rounded-pseudo shadow-lg"
                src="../../assets/images/case-studies/roomex_2.png"
                alt="Roomex Case Study"
              />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="container space-2 space-lg-3">
        <div className="row justify-content-center mb-5">
          <h2 className="text-center">{t('label.technologies')}</h2>
        </div>

        <div className="row align-items-center text-center">
          <figure className="col-4 col-lg-3 pb-4">
            <img
              className="max-w-11rem max-w-md-19rem mx-auto"
              src={typescriptIcon}
              alt="typescript"
            />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={angularIcon} alt="angular" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={nodeJsIcon} alt="nodejs" />
          </figure>
          <figure className="col-4 col-lg-3 pb-4">
            <img
              className="max-w-5rem max-w-md-11rem mx-auto"
              src={bootstrapIcon}
              alt="bootstrap"
            />
          </figure>
          <figure className="col-4 col-lg-4 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={awsIcon} alt="aws" />
          </figure>
          <figure className="col-4 col-lg-4 pb-4">
            <img className="max-w-11rem max-w-md-19rem mx-auto" src={rxjsIcon} alt="rxjs" />
          </figure>
          <figure className="col-4 col-lg-4 pb-4">
            <img className="max-w-9rem max-w-md-13rem mx-auto" src={ngrxIcon} alt="ngrx" />
          </figure>
        </div>
      </section>

      <section className="container overflow-hidden space-2">
        <div className="position-relative bg-dark text-center rounded mx-3 mx-md-6">
          <div className="space-1 space-md-2 space-top-lg-3 space-bottom-lg-2 position-relative z-index-2">
            <div className="w-md-75 mx-md-auto mb-6">
              <blockquote className="h3 text-white font-weight-normal text-lh-lg">
                “{t('homePage.services.1.testimonial')}“
              </blockquote>
            </div>

            <div className="w-lg-50 mx-lg-auto">
              <StaticImage
                className="rounded-circle avatar mb-2"
                src="../assets/images/testimonials/avatar_roomex.jpeg"
                alt="avatar"
                layout="fixed"
                width={50}
                height={50}
              />
              <h4 className="text-white mb-0">Karl Glennon</h4>
              <span className="d-block text-white-70">Co-Founder & CTO at Roomex</span>
            </div>

            <figure className="position-absolute bottom-0 right-0 left-0 z-index-n1 mb-6">
              <img src={abstractShapes22Svg} alt="SVG" />
            </figure>

            <figure className="position-absolute top-0 left-0 mt-6 ml-5">
              <img src={abstractShapes11Svg} alt="SVG" />
            </figure>
            <figure className="max-w-15rem w-100 position-absolute bottom-0 right-0">
              <div className="mb-n6 mr-n5">
                <img className="img-fluid" src={dots4Svg} alt="dot" />
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section
        className="container space-2 space-lg-3"
        style={{
          backgroundImage: `url(${abstractShapes19Svg})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-md-80 w-lg-50 mx-md-auto text-center">
          <h2>{t('label.buildProjectWithCodutech')}</h2>
          <p>{t('homePage.footer.subtitle')}</p>

          <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover mt-2">
            {t('label.getInTouch')}
          </LocalizedLink>
        </div>
      </section>
    </>
  );
};

export default RoomexCaseStudyPage;
